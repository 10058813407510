import { Dispatch, SetStateAction } from 'react'
import css from 'styled-jsx/css'
import { Swiper, SwiperSlide } from 'swiper/react'

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { shopHelpPath } from '@/helpers'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

type ModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  toForgotOrder?: () => void
}

const HowToFindOrderModal: React.FC<ModalProps> = ({ open, setOpen, toForgotOrder }) => {
  return (
    <>
      <style jsx>{modalStyle}</style>
      <div className={`modal tracking__tutorial${open ? ' is-active' : ''}`}>
        <div className="modal-background" onClick={() => setOpen(false)} />
        <div className="modal-content">
          <div className="box">
            <h3 className="tracking__tutorial__head">How to find out order number</h3>
            <div className="columns is-hidden-touch">
              <div className="column tracking__tutorial__item">
                <figure className="image is160x160">
                  <img
                    src={shopHelpPath(`tut_email_1.png`)}
                    alt="Tutorial icon"
                    className={`${open ? '' : ' is-hidden'}`}
                  />
                </figure>
                <p>Your order number is located at the top of your Order Confirmation Email</p>
              </div>
              <div className="column tracking__tutorial__item">
                <figure className="image is160x160">
                  <img
                    src={shopHelpPath(`tut_email_2.png`)}
                    alt="Tutorial icon"
                    className={`${open ? '' : ' is-hidden'}`}
                  />
                </figure>
                <p>
                  If you can not find it in your inbox, please check your spam filter or junked
                  folder
                </p>
              </div>
            </div>
            <div className="is-hidden-desktop">
              <Swiper
                slidesPerView={1}
                centeredSlides={true}
                slideToClickedSlide={true}
                spaceBetween={8}
                speed={250}
                watchSlidesVisibility={true}
                pagination={{ el: '.swiper-pagination', type: 'bullets' }}
              >
                <SwiperSlide>
                  <div className="column tracking__tutorial__item">
                    <figure className="image is180x180">
                      <img
                        src={shopHelpPath(`tut_email_1.png`)}
                        alt="Tutorial icon"
                        className={`${open ? '' : ' is-hidden'}`}
                      />
                    </figure>
                    <p>Your order number is located at the top of your Order Confirmation Email</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="column tracking__tutorial__item">
                    <figure className="image is180x180">
                      <img
                        src={shopHelpPath(`tut_email_2.png`)}
                        alt="Tutorial icon"
                        className={`${open ? '' : ' is-hidden'}`}
                      />
                    </figure>
                    <p>
                      If you can not find it in your inbox, please check your spam filter or junked
                      folder
                    </p>
                  </div>
                </SwiperSlide>
                <div className="swiper-pagination" />
              </Swiper>
            </div>
            <div className="tracking__tutorial__foot">
              <button
                type="button"
                className="button is-primary p-2"
                onClick={() => setOpen(false)}
                style={{ minWidth: '200px' }}
              >
                Thanks! I got it
              </button>
              {toForgotOrder && (
                <div className="field mt-4">
                  <div className="control">
                    <a className="is-link" onClick={toForgotOrder}>
                      Help! I didn’t receive order confirmation
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const modalStyle = css`
  .box {
    padding: 4rem;
  }
  .modal.tracking__tutorial .swiper-pagination-bullets {
    bottom: -5px !important;
  }

  .tracking__tutorial__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 240px;
    margin: auto;

    p {
      margin-top: 1rem;
    }
  }

  .tracking__tutorial .columns {
    justify-content: space-between;
    max-width: 536px;
    margin: auto;
  }

  .tracking__tutorial__head {
    font-size: 1.625rem;
    text-align: center;
    margin: 2rem 0;
    font-weight: 600;
  }

  .tracking__tutorial__foot {
    text-align: center;
    margin: 2rem 0 -0.75rem 0;

    button {
      min-width: 120px;
    }
  }

  .is-link {
    font-weight: normal;
    font-size: 15px;
    line-height: 32px;
  }

  @media screen and (max-width: 768px) {
    .tracking__tutorial__item {
      margin: auto;
      padding-bottom: 1rem;
    }

    .modal.tracking__tutorial {
      width: 100vw;

      .modal-content {
        max-width: calc(100vw - 2rem);
      }
    }
  }
`

export default HowToFindOrderModal
