import axios, { AxiosInstance } from 'axios'

import { createAuthRefreshInterceptor } from './refresh-token'
import { getLocalObject, setLocalObject } from '@/helpers'
import Router from 'next/router'

const pendingRequest = new Set()

export default function useAxios<ResponseType>(
  baseUrl = '',
  resultHandler?: {
    responseHandler?: (response: ResponseType) => ResponseType
  }
) {
  const instance: AxiosInstance = axios.create({
    baseURL: baseUrl || '',
    headers: {
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    withCredentials: true
    // timeout: 60000
  })

  const refreshToken = () => {
    const authLocal = getLocalObject('AUTH_LOGIN') as Types.LoginResponse
    if (!authLocal || !authLocal.access_token) {
      Router.push('/')
      return Promise.reject(null)
    }
    const body = {
      access_token: authLocal.access_token
    } as Types.RefreshTokenModel
    return instance.post('/auth/refresh', body).then((res) => setLocalObject('AUTH_LOGIN', res))
  }

  // TODO: Merge with interceptor of createAuthRefresh
  instance.interceptors.response.use(
    // @ts-ignore
    (response: AxiosResponse) => {
      const { data } = response as Types.HttpResponse
      if (Array.isArray(data) && resultHandler?.responseHandler) {
        return data.map((item) => {
          if (resultHandler.responseHandler) return resultHandler.responseHandler(item)
        })
      }
      return resultHandler?.responseHandler ? resultHandler.responseHandler(data) : data
    },
    (err) => {
      if (err?.response?.config?.url) {
        pendingRequest.delete(err?.response?.config?.url)
      }
    }
  )

  // TODO: Improve change to hook for better use
  createAuthRefreshInterceptor(instance, refreshToken)

  // Cancel duplicated Request
  instance.interceptors.request.use((config: any) => {
    const configTmp = { ...config }
    if (pendingRequest.has(config.url)) {
      configTmp.cancelToken = new axios.CancelToken((cancel) =>
        cancel('Cancel repeated request')
      ) as any
    } else {
      pendingRequest.add(config.url)
    }

    setTimeout(() => pendingRequest.delete(config.url), 3500)
    return configTmp
  })

  return {
    instance
  }
}
