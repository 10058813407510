import { isEmpty } from '@/helpers'
import useAxios from '../useAxios'
import { setLocalObject } from '@/helpers'
import { LOCAL_ORDER, LOCAL_ORDER_STATUS } from '@/contexts'

interface IAuthContainer {
  login: (body: Types.LoginModel, clearLocal?: boolean) => Promise<Types.LoginResponse>
  loginFromEmail: (token: string) => Promise<Types.LoginResponse>
  refreshToken: () => Promise<Types.LoginResponse>
}

const authContainer = {} as IAuthContainer

export function useAuthService(basePath = ''): IAuthContainer {
  if (!isEmpty(authContainer)) {
    return authContainer
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { instance } = useAxios<Types.LoginResponse>(basePath)
  authContainer.login = (body: Types.LoginModel, clearLocal = true) => {
    if (clearLocal) {
      window.localStorage.removeItem('AUTH_LOGIN')
      window.localStorage.removeItem(LOCAL_ORDER)
      window.localStorage.removeItem(LOCAL_ORDER_STATUS)
    }
    Object.keys(body).forEach((key: string) => {
      const temp = key as keyof Types.LoginModel
      if (body[temp] && typeof body[temp] === 'string') {
        // @ts-ignore
        body[temp] = body[temp].trim()
      }
    })
    if (body.email) body.email = body.email!.toLowerCase()
    const { order } = body
    if (order && order.indexOf('#') < 0) {
      body.order = `#${order}`
    }
    return instance.post('/auth/login', body).then((res: any) => {
      setLocalObject('AUTH_LOGIN', {
        ...res,
        phone: body.phone,
        email: body.email
      })
      return res as Types.LoginResponse
    })
  }
  return authContainer
}
