import Link from 'next/link'
import { FC, PropsWithChildren, useMemo } from 'react'

export type IShLinkProps = {
  pathName: string
  className?: string
  query?: Types.PlainObject
  disableForwardableQuery?: boolean
}

const ShLink: FC<PropsWithChildren<IShLinkProps>> = (props) => {
  const buildQuery = useMemo(() => {
    let query: Types.PlainObject = { ...props.query }
    if (!props.disableForwardableQuery) {
      query = {
        ...query
      }
    }

    return query
  }, [props])

  return (
    <Link href={{ pathname: props.pathName, query: buildQuery }}>
      <a className={props.className}>{props.children}</a>
    </Link>
  )
}

export default ShLink
