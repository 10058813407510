import {
  createFormDataBody,
  getQueryParams,
  isEmpty,
  getCustomerRequest,
  getTicketType,
  updateTrackingStatus
} from '@/helpers'
import useAxios from '../useAxios'

export enum TicketType {
  FORGOT_ORDER = 'FORGOT_ORDER',
  WHERE_ORDER = 'WHERE_ORDER',
  MODIFY_ORDER = 'MODIFY_ORDER',
  CLAIM_ORDER = 'CLAIM_ORDER'
}

export enum TicketState {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
  SUBMITTED = 'SUBMITTED'
}

export enum TicketAction {
  ORDER_STATUS = 'ORDER_STATUS',
  QUESTION_ORDER_LOST = 'QUESTION_ORDER_LOST',
  QUESTION_ORDER_LATE = 'QUESTION_ORDER_LATE',
  UPDATE_SHIPPING = 'UPDATE_SHIPPING',
  CANCEL_ORDER = 'CANCEL_ORDER',
  CHANGE_VARIANT = 'CHANGE_VARIANT',
  REPLACE_ORDER = 'REPLACE_ORDER',
  KEEP_AND_GET_REFUND = 'KEEP_AND_GET_REFUND',
  RETURN_ORDER = 'RETURN_ORDER'
}

export enum FreshdeskTicketStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED'
}

type PagingParams = {
  page?: number
  size?: number
}

interface IOrderContainer {
  relative: (params?: PagingParams) => Promise<Types.Order[]>
  detailById: (id: string) => Promise<Types.Order>
  detailByInfo: (params: Types.LoginModel) => Promise<Types.Order>
  createReview: (poId: string, body: Types.ReviewRequest) => Promise<Types.ShophelpRequest>
  updateReview: (
    poId: string,
    id: string,
    body: Types.ReviewRequest
  ) => Promise<Types.ShophelpRequest>
  // history: (poId: string) => Promise<Types.PoHistory[]>
  tickets: (params: Partial<Types.TicketRequest>) => Promise<Types.ShophelpRequest>
  updateTickets: (params: {
    id: string
    ticket_state: TicketState
    description: string
  }) => Promise<Types.ShophelpRequest>
  findOrder: (params: Types.FindOrderRequest) => Promise<Types.ShophelpRequest>
  getListTickets: (params: {
    poId: string
    page?: number
    size?: number
  }) => Promise<Types.TicketModel[]>
  getDiscussions: (params: {
    poId: string
    id: string
    page?: number
    size?: number
  }) => Promise<Types.DiscussionModel[]>
  replyDiscussion: (body: {
    files: File[]
    ticket_id?: string
    body: any
    poId?: string
    requester_id?: string
  }) => Promise<Types.DiscussionModel>
  markAsRead: (body: { poId: string; ticket_id: string }) => Promise<boolean>
}

const orderContainer = {} as IOrderContainer

export function useOrderService(basePath = ''): IOrderContainer {
  if (!isEmpty(orderContainer)) {
    return orderContainer
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { instance } = useAxios<Types.Order>(basePath)

  orderContainer.detailById = (id: string) => {
    return instance.get(`/order/${id}/detail`).then((order: any) => {
      return {
        ...order,
        tracking_infos: order?.tracking_infos?.map(updateTrackingStatus) || []
      }
    })
  }

  orderContainer.detailByInfo = (params: Types.LoginModel) => {
    const { order: order_name, ...body } = params
    return instance
      .get(`/order/detail${getQueryParams({ ...body, order_name })}`)
      .then((order: any) => {
        return {
          ...order,
          tracking_infos: order?.tracking_infos!.map(updateTrackingStatus)
        }
      })
  }

  orderContainer.relative = (params: PagingParams = { page: 1, size: 100 }) => {
    return instance.get(`/order/relative${getQueryParams(params)}`).then((res: any) =>
      res.map((order: Types.Order) => {
        return {
          ...order,
          tracking_infos: order?.tracking_infos!.map(updateTrackingStatus)
        }
      })
    )
  }

  orderContainer.createReview = (poId: string, body: Types.ReviewRequest) => {
    let url = '/order/{poId}/review'
    url = url.replace('{poId}', poId)
    const formData = createFormDataBody(body)
    return instance.post(url, formData)
  }

  orderContainer.updateReview = (poId: string, id: string, body: Types.ReviewRequest) => {
    let url = '/order/{poId}/review/{id}'
    url = url.replace('{poId}', poId)
    url = url.replace('{id}', id)
    const formData = createFormDataBody(body)
    return instance.put(url, formData)
  }

  orderContainer.tickets = (params: Partial<Types.TicketRequest>) => {
    const url = '/order/tickets'
    const data = new FormData()
    if (isEmpty(params)) {
      throw new Error('Not enough data to request a support ticket!')
    }
    params.customer_request = getCustomerRequest(params)
    params.ticket_type = getTicketType(params as Types.TicketRequest)
    params.source = 'ECOMMERCE'
    Object.entries(params).forEach(([key, value]: [string, any]) => {
      if (key === 'address') {
        for (const field in value) {
          data.append(`address.${field}`, value[field].trim())
        }
      } else if (key === 'attachments') {
        for (const file of value) {
          data.append('attachments', file)
        }
      } else if (key === 'item_ids') {
        for (const id of value) {
          data.append('item_ids', id)
        }
      } else {
        data.append(key, value ? value.trim() : '')
      }
    })

    return instance.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((data: any) => {
      if (data) {
        return data
      } else {
        console.error('Server return no response when request create a support ticket')
        throw {
          status: 404
        }
      }
    })
  }

  orderContainer.updateTickets = (params: {
    id: string
    ticket_state: TicketState
    description: string
  }) => {
    const url = `/order/tickets/${params.id}`
    const data = new FormData()

    Object.entries(params).forEach(([key, value]: [string, any]) => {
      data.append(key, value.trim())
    })

    return instance.put(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((data: any) => {
      if (data) {
        return data
      } else {
        console.error('Server return no response when request update a support ticket')
        throw {
          status: 404
        }
      }
    })
  }

  orderContainer.findOrder = (params: Types.FindOrderRequest) => {
    const url = '/order/find-order'
    params.source = 'PORTAL'
    return instance.post(url, params)
  }

  orderContainer.getListTickets = (params: { poId: string; page?: number; size?: number }) => {
    let url = '/order/{poId}/tickets'
    url = url.replace('{poId}', params['poId'] + '')

    const pagingParams = {
      page: params.page || 1,
      size: params.size || 100
    }
    return instance.get(`${url}${getQueryParams(pagingParams)}`)
  }

  orderContainer.getDiscussions = (params: {
    poId: string
    id: string
    page?: number
    size?: number
  }) => {
    let url = '/order/{poId}/tickets/{id}/discussions'
    url = url.replace('{poId}', params['poId'] + '')
    url = url.replace('{id}', params['id'] + '')

    const pagingParams = {
      page: params.page || 1,
      size: params.size || 100
    }
    return instance.get(`${url}${getQueryParams(pagingParams)}`)
  }

  orderContainer.replyDiscussion = (body: {
    poId?: string
    ticket_id?: string
    requester_id?: string
    body: string
    files: File[]
  }) => {
    let url = '/order/{poId}/tickets/{id}/discussions'
    url = url.replace('{poId}', body['poId'] + '')
    url = url.replace('{id}', body['ticket_id'] + '')

    return instance.post(url, createFormDataBody(body), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  orderContainer.markAsRead = (body: { poId: string; ticket_id: string }) => {
    let url = '/order/{poId}/tickets/{ticket_id}/discussions'
    url = url.replace('{poId}', body['poId'] + '')
    url = url.replace('{ticket_id}', body['ticket_id'] + '')

    return instance.put(url, { ticket_id: body.ticket_id })
  }

  return orderContainer
}
