import css from 'styled-jsx/css'
import ShLink from '../next/ShLink'
import { shopHelpPath } from '@/helpers'

type HeaderProps = {
  type?: 'default' | 'center'
}

const Header: React.FC<HeaderProps> = ({ type = 'default', ...props }) => {
  return (
    <div {...props} className="app-header">
      <style jsx global>
        {globalStyle}
      </style>
      <div className="container ">
        <ShLink
          pathName="/"
          className={`is-flex is-align-items-center is-fullwidth ${
            type === 'center' ? 'is-justify-content-center' : ''
          }`}
        >
          <figure className="image is176x40">
            <img src={shopHelpPath(`logo.png`)} alt="ShopHelp Logo" />
          </figure>
          {/*<span className="app-header__title ml-3 mr-6">{title}</span>*/}
          {type !== 'center' && (
            <span className="ml-8 app-header__subtitle is-hidden-touch">
              Where eCommerce Deals Exceed Customer Satisfaction!
            </span>
          )}
        </ShLink>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .app-header {
    background: var(--indigo-2);

    .container {
      display: flex;
      align-items: center;
      min-height: 80px;
      width: 100%;
    }

    &__title {
      font-size: 24px;
      line-height: 40px;
      font-weight: bold;
      color: var(--gray-8);
    }

    &__subtitle {
      line-height: 32px;
      color: var(--gray-8);
    }

    .is-fullwidth {
      width: 100%;
    }
  }
`

export default Header
